.App {
  font-size: 14pt;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App a {
  color: white;
}

.App td {
  padding-right: 16px;
  padding-top: 8px;
}

.App .spinner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App .spinner span {
  padding: 10px;
}

.App-header {
  background-color: #252627;
  padding: 16px;
  height: 32px;
}

.App-header img {
  height: 32px;
  display: block;
}

.App-contents {
  padding: 16px;
  width: 100%;
}

.App-section-header {
  padding-top: 8px;
  font-size: 10pt;
  color: #aeaeae;
}

.App-contents .Jobs .Job {
  padding-top: 8px;
}

.App .Collapsible-Opened {
  margin: 8px 0px 8px 0px;
  color: #cdcdcd;
}

.App .Collapsible-Opened pre {
  padding: 8px 0px 8px 0px;
  overflow: auto;
}

.App .Collapsible-Closed {
  padding: 8px 0px 8px 0px;
  color: #cdcdcd;
}

.App .Collapsible-Trigger-Closed {
  padding: 8px 0px 8px 0px;
  color: white;
}

.App .Collapsible-Trigger-Opened {
  padding: 8px 0px 8px 0px;
  color: white;
}

.App .Collapsible-Closed pre {
  padding: 8px 0px 8px 0px;
}

.App pre {
  padding: 0px 0px 16px 0px;
  font-size: 10pt;
  margin: 0;
  color: #cdcdcd;
}

.App-link {
  color: #61dafb;
}
